.__radio-select__1fdbd {
  height: 30px;
  width: 30px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  fill: $white;
}
