.__add-payment-method-modal__69a8d__Modal {
  .modal-dialog {
    max-width: 550px;
  }

  .modal-content {
    padding: 60px !important;

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      > div {
        width: 100%;
      }
    }

    .card-icon {
      position: relative;
      width: 300px !important;
      padding-top: 35px;
      padding-left: 15px;
      min-height: 175px;
      border-radius: 10px;
      background-color: theme-color('primary');
      transition: background-color .5s;

      &.has-errors {
        background-color: theme-color('danger');
      }

      .card-vendor {
        bottom: 10px;
        right: 10px;
        height: 34px;
        width: 48px;
        border-radius: 5px;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;

        &.amex {
          background-image: url('/assets/images/amex.png');
        }

        &.visa {
          background-image: url('/assets/images/visa.png');
        }

        &.mastercard {
          background-image: url('/assets/images/mastercard.png');
        }

        &.discover {
          background-image: url('/assets/images/discover.png');
        }
      }

      .card-chip {
        height: 44px;
        width: 20%;
        border-radius: 5px;
        background-color: $white;
      }

      .card-number {
        @include text(36px);
      }

      .card-name {
        @include text(18px);
      }
    }
  }
}
