.__empty-block__74ecc {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    @include text(36px);
    color: $white;
    opacity: .5;
  }

  p {
    @include text(14px);
    color: $white;
    opacity: .5;
  }

  a {
    color: theme-color('primary');
  }
}
