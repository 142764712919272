.__charging-dji__b98c5 h2 {
  @include text(36px);
  margin: 0;
}

.__charging-dji__b98c5 button.action {
  @include text(14px);
  padding: 10px 30px;
}

.__charging-dji__b98c5 .modal-content {
  max-width: 500px;
}

.__charging-dji__b98c5 .modal-body-container {
  margin: 2em 0;
  padding: 0 2em;
}

.__charging-dji__b98c5 .warning {
  color: $amber;
  font-weight: bold;
}
