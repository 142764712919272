.__dropdown-input__31b21 .container {
  position: relative;
  padding: 0;
  margin-bottom: 1rem;

  input {
    width: 100%;
    background: #333333;
    border-radius: 4px;
    height: 36px;
    font-size: 14px;
    color: white;
    padding: 0 19px;
  }

  input:focus {
    outline: 0;
    border: 1px solid #31d1a1;
  }

  .dropdown-list-container {
    position: absolute;
    margin-top: 10px;
    background: #333333;
    z-index: 100;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    width: 100%;
    font-size: 14px;

    .heading {
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.5);
      padding: 20px;
    }
  }

  .dropdown-list {
    display: block;
  }

  .dropdown-list-item {
    padding: 5px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
  }
  
  .dropdown-list-item:hover {
    background: #545454;
  }
}
