.__user-list__f60a9 {
  .break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
  }

  > header {
    h2 {
      @include text(14px, true);
      opacity: .5;
      text-transform: uppercase;
    }
  }

  &__Modal {
    .title {
      font-size: 28px;
    }

    .description {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      button {
        width: 35%;
      }
    }
  }

  &__User {
    border-top: 1px solid $border-color;

    &.pending {
      .info {
        opacity: .5;
      }
    }

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    .actions {
      @include text(14px, true);
      color: theme-color('primary');

      > * {
        margin-left: 35px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
