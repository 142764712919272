label {
  @include text(14px, true);
  margin-bottom: 3px;
  text-transform: uppercase;
}

textarea.form-control,
textarea.form-control:focus,
input.form-control,
input.form-control:focus {
  @include text(16px);
  box-shadow: none;
  outline: none;
  border: 1px solid #95989A;
  background-color: transparent;
  color: $white;
}

.textarea-wrapper,
.select-wrapper,
.input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 30px;
  background-color: #333333;
  padding: 5px;

  .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    border: none;

    .input-group-addon {
      border: none;
      border-radius: 30px;
      background-color: transparent;
      color: theme-color('primary');

      &:first-child {
        padding-left: 5px;
      }

      &:last-child {
        padding-right: 5px;
      }
    }
  }

  textarea,
  input {
    border: none;
    padding: 0 10px;
    background-color: transparent;

    &:focus {
      outline: none !important;
      border: none !important;
    }
  }

  textarea {
    min-height: 75px;
    height: auto;
    resize: none;
    padding: 0;
  }

  .form-control {
    @include text(16px);
    box-shadow: none;
    outline: none;
    background-color: transparent;
    border: none;
    height: calc(1.25rem + 2px) !important;
    color: theme-color('primary');
  }

  select.form-control {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  &.rounded {
    border-radius: 30px !important;
  }
}

.textarea-wrapper {
  background-color: lighten($background, 10%);
  border: none;
  border-radius: $border-radius;

  &.rounded {
    border-radius: 0 !important;
  }
}

.form-control-plaintext {
  @include text(16px, true);
  color: theme-color('white');
  padding: 10px;
  height: auto;
}

.error {
  color: red
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
