.__user-edit-modal__af677 {
  > .ember-view {
    display: none;
  }

  .form-group {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.__user-edit-modal__af677 .modal-dialog {
	max-width: 350px;
}
