.__stacked-bar__9c3a9 {
  --light: theme-color('white');
  --dark: #414141;

  &--success { --light: #{$shamrock}; }
  &--warning { --light: #{$amber}; }
  &--danger { --light: #{$amaranth}; }

  display: flex;
  flex-direction: row;
  background-color: var(--dark);

  > div {
    width: 100%;
    height: 3px;

    &:first-child {
      background-color: theme-color('white');
    }

    &:last-child {
      background-color: var(--light);
    }
  }
}
