.__hardware-fault__5d72f h2 {
  @include text(36px);
  margin: 0;
}

.__hardware-fault__5d72f button.action {
  @include text(14px);
  padding: 10px 30px;
}

.__hardware-fault__5d72f .modal-content {
  max-width: 500px;
}

.__hardware-fault__5d72f .modal-body-container {
  margin: 2em 0;
  padding: 0 2em;
}

.__hardware-fault__5d72f .warning {
  color: $amber;
  font-weight: bold;
}
