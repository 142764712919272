.__contact-us__82855 h2 {
  @include text(36px);
  margin: 0;
}

.__contact-us__82855 button.action {
  @include text(14px);
  padding: 10px 30px;
}

.__contact-us__82855 .modal-content {
  max-width: 500px;
}

.__contact-us__82855 .modal-body-container {
  margin: 2em 0;
}

.__contact-us__82855 .warning {
  color: $amber;
  font-weight: bold;
}
