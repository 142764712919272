.__page-header__1478b {
  color: $white;

  h1 {
    @include text(36px, true);
    margin-bottom: 0;
    text-transform: capitalize;

    &.with-icon {
      padding-left: 25px;

      i {
        position: absolute;
        top: 10px;
        left: 15px;
        @include text(24px, true);
        color: theme-color('primary');
      }
    }
  }

  h4 {
    @include text(36px);
    margin: 0;
  }

  .super-nav {
    > i.mdi {
      @include text(28px);
    }
  }

  .nav-link {
    @include text(14px, true);
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    color: theme-color('primary');

    i {
      margin-right: 5px;
    }
  }
}
