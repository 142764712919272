.__change-payment-method-modal__2ec52__Modal {
  .modal-content {
    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .btn-action {
        fill: var(--state-dark);
        background-color: $white;
    
        &:hover {
          fill: var(--state-dark);
          background-color: $white;
        }
      }

      > div {
        width: 100%;
      }
    }
  }
}
