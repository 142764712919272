.__range-slider__bf15b {
  @media (max-width: 768px) 
  {
    .value-label {
      font-size: 0.64rem !important;
    }
  }

  @media (min-width: 768px) 
  {
    .value-label {
      font-size: 0.8rem !important;
    }
  }

  .text-sub {
    @include text(10px);
  }

  .slider {
    width: 100%;

    .slider-track {
      height: 2px;
      background-color: #545454;
      margin-top: 0;

      .slider-selection {
        background: theme-color('primary');
      }
    }

    .slider-handle {
      background: $white;
    }

    .slider-tick {
      height: 9px;
      width: 9px;
      margin-top: 6px;
      margin-left: -5px;
      opacity: 1;
      background: $white;

      &.in-selection {
        background: theme-color('primary');
      }
    }

    .slider-tick-label {
      @include text(10px);
      color: rgba(#fff, .63);
    }
  }
}
