::-webkit-scrollbar {
  @include transition();

  width: 10px;
  height: 10px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  @include transition();

  background-color:rgba(80, 80, 80, 1);
  border: 2px solid transparent;
  border-radius: 50px;
  background-clip: padding-box;
}
::-webkit-scrollbar-track {
  background-color:rgba(50, 50, 50, 0.05);
}
