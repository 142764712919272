.__forgot-password-form__5d6e2 {
  background: none;
  border: none;

  .form-group {
    &:first-child {
      margin-top: 45px;
    }

    &:last-child {
      margin-top: 90px;
    }
  }
}
