.__more-options__b04c3 {
  .btn-action {
    height: 25px;
    width: 25px;
    border-radius: 30px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--state-dark);
    fill: var(--state-light);

    &:hover {
      fill: var(--state-dark);
      background-color: $white;
    }

    &:after {
      display: none;
    }
  }

  .dropdown-divider {
    border: 1px solid #707070;
  }

  .dropdown-menu {
    background: #313131;
    border: 1px solid #707070;
    border-radius: 0;
    padding: 0;
    width: 200px;
    right: 0;
    left: auto;

    a {
      @include text(14px);
      text-transform: none;
      padding: 5px 10px;
      background: #313131;
      color: $white;

      &:hover {
        color: $white;
        transition: background-color .5s;
        background: $gray-900;
      }
    }
  }
}

