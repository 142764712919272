.__inventory-stats-list__3b807 {
  li {
    @include text(14px);
    font-weight: bold;
    margin-bottom: 1.5em;
  }

  i {
    @include font-size(10px);
  }
}
