.__activity-floating-bar-graph__554b1 .graph-title {
  text-align: center;
}

.__activity-floating-bar-graph__554b1 .graph-container {
  padding: 0px 5px;
}

.__activity-floating-bar-graph__554b1 .graph-canvas {
  overflow: auto;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  padding-bottom: 10px;
}

.__activity-floating-bar-graph__554b1 .x-axis-label {
  text-anchor: middle;
  fill: white;
}

.__activity-floating-bar-graph__554b1 .axis path,
.__activity-floating-bar-graph__554b1 .axis line {
  fill: none;
  font: 10px sans-serif;
  stroke: #ffffff;
  shape-rendering: crispEdges;
}
.__activity-floating-bar-graph__554b1 .axis text {
    fill: #ffffff;
}

.__activity-floating-bar-graph__554b1 .bar {
    fill: #31D1A1;
}

.__activity-floating-bar-graph__554b1 .datalabel {
    fill: #ffffff;
    font: 10px sans-serif;
    shape-rendering: crispEdges;
}

.__activity-floating-bar-graph__554b1 .legend {
    padding: 5px;
    font: 10px sans-serif;
    background: yellow;
    box-shadow: 2px 2px 1px #888;
}

.__activity-floating-bar-graph__554b1 .tooltip {
    background: rgba(35,35,35, 0.98);
    box-shadow: 0 0 5px rgba(49, 209, 161, 0.5);
    color: #ffffff;
    font-size: 12px;
    left: 130px;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 95px;
    z-index: 10;
    display: block;
    opacity: 0;
}

.__activity-floating-bar-graph__554b1 .label {
    font: 10px sans-serif;
  stroke: grey;
  shape-rendering: crispEdges;
}