.__action-button__76702 {
  transition: background-color .5s, fill .5s;
  height: 25px;
  width: 25px;
  padding: 2px;
  display:flex;
  border-radius: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--state-dark);
  fill: var(--state-light);

  &:hover {
    fill: var(--state-dark);
    background-color: $white;
  }
}
