.__discharging-dji__64620 h2 {
  @include text(36px);
  margin: 0;
}

.__discharging-dji__64620 button.action {
  @include text(14px);
  padding: 10px 30px;
}

.__discharging-dji__64620 .modal-content {
  max-width: 500px;
}

.__discharging-dji__64620 .modal-body-container {
  margin: 2em 0;
  padding: 0 2em;
}

.__discharging-dji__64620 .warning {
  color: $amber;
  font-weight: bold;
}
