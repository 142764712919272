.__charger-states-list__f7d0a {
  --state: #{$white};
  --state-dark: #353535;

  .center-title {
    color: $white !important;
    opacity: 0.5
  }
}

.__charger-states-list__f7d0a__ListItem {
  margin-bottom: 45px;

  header {
    @include text(14px);
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
  }
}
