html {
  @include text(16px);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-family: true;
}

i.mdi {
  @include font-size(18px);
}

@each $theme, $color in $theme-colors {
  .text-#{$theme} { color: $color; }
}

.text-muted {
  color: rgb(112, 112, 112) !important
}

.text-normalcase {
  text-transform: none;
}

$font-sizes: () !default;
$font-sizes: map-merge((
  'xxs': 10px,
  'xs': 12px,
  'sm': 14px,
  'md': 18px,
  'lg': 24px,
  'xl': 36px,
), $font-sizes);

@each $size, $px in $font-sizes {
  .text-#{$size} { @include text($px); }
}

@each $name, $color in $theme-colors {
  .text-#{$name} {
    color: theme-color($name);
  }
}
