.__select-battery__6a97b .modal-header {
  display: flex;
  flex-direction: column;

  .subtitle {
    margin-top: 24px;
    font-size: 14px;
  }
}

.__select-battery__6a97b .modal-footer {
  text-align: right;
}

.__select-battery__6a97b .row {
  margin-bottom: 30px;

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px;

    .title {
      font-size: 18px;
    }

    .description {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.__select-battery__6a97b .icon {
  background: #353535;
  border: 1px solid #353535;
  height: 71px;
  width: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.__select-battery__6a97b .icon:hover {
  border: 1px solid white;
}

.__select-battery__6a97b .modal-dialog {
  max-width: 500px;
}