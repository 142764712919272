$shamrock: #31D1A1;
$mineshaft: #333333;
$amaranth: #F3304A;
$white: #ffffff;
$amber: #F9C10B;
$background: #232323;

$theme-colors: (
  "primary": $shamrock,
  "secondary": $mineshaft,
  "success": $shamrock,
  "danger": $amaranth,
  "white": $white,
  "warning": $amber,
);

$idle-bg: #545454;
$idle-light: $white;
$idle-dark: rgba(108, 108, 108, 1);

$charging-light: $shamrock;
$charging-dark: rgba(44, 86, 73, 1);
$charging-bg: #23332F;
$charging-border: $shamrock;

$disconnected-bg: #353535;
$disconnected-light: #B4B4B4;

$alert-bg-level: 0 !default;
$alert-border-level: 0 !default;
$alert-color-level: -12 !default;

:root {
  --state-charging-light: #{$shamrock};
  --state-charging-dark: #123027;

  --state-idle-light: #{$white};
  --state-idle-dark: #414141;

  --state-storage-light: #{$amber};
  --state-storage-dark: #4E431E;

  --state-disconnected-light: #414141;
  --state-disconnected-dark: #414141;
}
