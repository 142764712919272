.__plan-list__98b0f {
  .plan {
    background: #414141;

    &.current {
      p {
        @extend .text-muted;
      }
    }

    h2 {
      @include text(32px);
    }

    h4 {
      @include text(16px);
    }

    p {
      color: theme-color('primary');
    }

    .btn {
      margin-top: 20px;
    }
  }
}

