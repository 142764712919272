.__frame__inventory__index__2bcc8__battery-list-header {
  .sm-btn {
    padding: 0rem 0.7rem
  }
}

.__frame__inventory__index__2bcc8 {
  .panel-heading a {
    display: flex;
  }

  @media (max-width: 768px) 
  {
      .noMobile
      {
          display: none;
      }
  }

  @media (min-width: 768px) 
  {
    .panel-heading {
      display: none;
    }
  }

  .panel-heading .chevron:before {
    content: "\f078";   
  }
  
  .panel-heading .collapsed .chevron:before {
    content: "\f054";   
  }
}
