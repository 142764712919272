.__bulk-battery-state-selection__5421c {
  --state-light: #{$white};
  --state-dark: #353535;

  @include text(14px);
  min-height: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  text-transform: uppercase;
  color: #A5A5A5;

  & > .action-group {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    width: auto;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: auto;
    }

    > .action {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      > a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
