/* main container */
.ember-notify {
  right: 10px;
  bottom: 10px;
  border-radius: 0;

  &.error {
    color: white;
    background: theme-color('danger');
    border-color: darken(theme-color('danger'), 15%);
  }

  &.success {
    color: $background;
    background: theme-color('success');
    border-color: darken(theme-color('success'), 15%);
  }

  .close {
    color: $background;
    opacity: .8;
    text-shadow: none;
  }
}
