.ember-modal-wrapper {
  .ember-modal-overlay {
    &.translucent {
      background: rgba(0,0,0,.5);
    }
  }

  .modal {
    display: block;
  }

  .modal-dialog {
    width: 100vw;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    background-color: $background;
    border: none;
    padding: 0;
  }

  .modal-content {
    font-weight: bold;
    background-color: $background;
    padding: 40px;
    border: none;
  }

  .modal-body {
    padding: 40px 0;
  }

  .modal-footer {
    display: block;
  }
}
