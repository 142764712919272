
.__grouped-bar-graph__23ca3 .graph-title {
  text-align: center;
}

.__grouped-bar-graph__23ca3 .graph-container {
  overflow: auto;
}

.__grouped-bar-graph__23ca3 .axis path,
.__grouped-bar-graph__23ca3 .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
}