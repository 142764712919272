.__create-bulk-batteries__a2230 .modal-dialog {
  max-width: 80vw;
}

.__create-bulk-batteries__a2230 .create-bulk-form {
  padding-top: 10px;
}
.__create-bulk-batteries__a2230 .create-bulk-form:nth-child(2n+3) {
  background-color: black;
}
