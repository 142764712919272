.tooltip {
  font-size: 0.875rem
}

.tooltip-inner {
  max-width: 280px;
  padding: 0.4rem 0.6rem;
  color: #ffffff;
  text-align: left;
  background-color: #000;
  border-radius: 0.25rem;
}
