.__configure-charger__0ccf5 h2 {
  @include text(36px);
  margin: 0;
}

.__configure-charger__0ccf5 button.save {
  @include text(14px);
  padding: 10px 50px;
  margin-left: auto;
}

