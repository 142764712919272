.__choose-existing__b0f6c h2 {
  @include text(36px);
  margin: 0;
}

.__choose-existing__b0f6c button.action {
  @include text(14px);
  padding: 10px 30px;
  margin-right: 15px;
}

.__choose-existing__b0f6c .modal-footer {
  margin-top: 40px;
  text-align: right;
}

.__choose-existing__b0f6c .modal-dialog {
  max-width: 500px;
}

.__choose-existing__b0f6c .list-item{
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.__choose-existing__b0f6c .list-item:hover {
  background: #545454;
}

.__choose-existing__b0f6c .description {
  font-size: 10px;
  color: #A5A5A5;
}