.__stripe-card-form__79ff2 {
  position: relative;
  width: 100%;

  .stripe-card-wrapper {
    padding: 8px 15px;
    display: block;
    border-radius: 30px;
    background-color: #333333;
  }
}
