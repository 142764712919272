html,
body,
body > .ember-view,
.page-content > .ember-view,
body > .ember-view > .application {
  display: flex;
  flex-direction: column;
  background-color: $background;
  color: $white;
  height: 100%;
}

.page-content {
  height: 100%;
}

.page-content, #modal-overlays {
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

ul {
  list-style-type: none;

  &.horizontal {
    display: flex;
    flex-direction: row;
  }
}

a {
  transition: color .5s, opacity .5s;
  color: $white;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    text-decoration: none;
  }

  &.active {
    color: theme-color('primary');
    opacity: 1;
  }

  &.muted {
    opacity: .3;

    &:hover,
    &.active {
      opacity: 1;
    }
  }
}

.ember-load-indicator {
  height: 3px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: $background;

  &:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: theme-color('primary');
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
  }
}

.inventory-padder {
  padding-left: 30px;
  padding-right: 30px;
}

section {
  > header {
    @include text(14px);
    color: #707070;
    text-transform: uppercase;
  }
}

.Plans {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-down(sm) {
    display: block;
  }

  .Plans__header {
    margin: 5em 0 2em;
    flex: 1 1 auto;
    width: 100%;

    &.is-expired {
      margin-bottom: 2em;
    }

    > h1, > p {
      text-align: center;
    }
  }
}

.alert {
  display: flex;
  flex-direction: row;

  .icon {
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-right: 15px;
  }
}

section {
  header {
    h1 {
      @include text(14px);
      @extend .muted;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.loading-font {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2em;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
