.__charger-state-details-bay__c1c79 {
  --state-light: var(--state-idle-light);
  --state-dark: var(--state-idle-dark);

  @include font-size(14px);
  transition: background-color .25s;
  display: flex;
  flex-direction: column;
  border: none;
  background: none;
  color: var(--state-light);
  margin-bottom: 20px;
  padding: 15px 15px;
  border-radius: 4px;
  user-select: none;

  a {
    color: inherit;
  }

  &--disconnected, &--empty {
    --state-light: var(--state-disconnected-light);
    --state-dark: var(--state-disconnected-dark);

    &:hover {
      background-color: $background !important;

      .action-group > .action {
        display: none !important;
      }
    }
  }

&--faulty,
&--check-balance-leads,
&--check-battery-leads,
&--incorrect-battery,
&--over-voltage,
&--under-voltage,
&--cell-imbalance,
&--over-temperature,
&--hardware-fault {
    background: theme-color('danger') !important;
  
    .name,
    .status,
    .percent {
      color: theme-color('white');
      opacity: 1;
    }
  
    .muted {
      opacity: .5;
    }
  
    .progress {
      display: none;
    }
  }

  &--baseline-hold, &--baseline-init-hold, &--charging-hold, &--storage-hold, &--configuration-error {
    background: #67b8ff !important;
  
    .name,
    .status,
    .percent {
      color: theme-color('white');
      opacity: 1;
    }
  
    .muted {
      opacity: .5;
    }
  
    .progress {
      display: none;
    }
  }

  &--charging, &--baseline-init, &--baseline-charging {
    --state-light: var(--state-charging-light);
    --state-dark: var(--state-charging-dark);
  }

  &--storage, &--airplane-storage, &--baseline-discharging {
    --state-light: var(--state-storage-light);
    --state-dark: var(--state-storage-dark);
  }

  &--new, &--empty-dji {
    --state-dark: #353535;
    --state-light: #{$white};

    padding: 0 15px;

    .bay-header {
      padding: 15px 15px 15px 15px;
      color: var(--state-dark);
      min-height: 60px;
      height: 60px;
      border-radius: 4px;

      .action-group {
        .action {
          > .btn {
            transition: background-color .5s;
            background: $white;
            border: 1px solid var(--state-dark);
    
            &:hover {
              color: $white;
              background-color: var(--state-dark);
            }
          }
        }
        
        &:last-child {
          > .action {
            display: flex !important;

            > .btn {
              border: 1px solid var(--state-dark);
            }

            .btn-action {
              fill: var(--state-dark);
              background: var(--state-light);
            }

            &:hover {
              .btn-action {
                fill: var(--state-light);
                background: var(--state-dark) !important;
              }
            }
          }
        }
      }
    }
  }

  &--new {
    .bay-header {
      background-color: $white;
    }

    &:hover {
      background-color: $background !important;

      .bay-header {
        background-color: $white !important;
      }
    }
  }

  &--empty-dji {
    --state-light: rgb(191, 191, 191);
    .bay-header {
      background-color: var(--state-light)
    }

    &:hover {
      background-color: $background !important;

      .bay-header {
        background-color: var(--state-light) !important;
      }
    }
  }

  &__current,
  &__charge {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;

      .text {
        @include text(12px, true);

        flex: 1;
        display: flex;
        flex-direction: row;

        span {
          &:last-child {
            margin-left: auto;
          }
        }
      }

      .progress {
        margin-top: 5px;
        height: 3px;
        border-radius: 5px;

        .progress-bar {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__cells {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-top: 10px;

    .data {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      span {
        &:first-child {
          @include text(14px, true);
          margin-right: 15px;
        }

        &:last-child {
          @include text(12px);
        }
      }
    }

    ul {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      justify-content: space-between;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        flex-direction: row;
        align-items: flex-end;


        span {
          @include font-size(10px);
          margin-left: 3px;
        }

        .progress {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 40px;
          width: 3px;
          border-radius: 5px;
          margin-bottom: 5px;

          .progress-bar {
            transform: rotate(180deg);
            width: 100%;
          }
        }
      }
    }
  }

  .bay-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    .confirm-error {
      height: 30px;
    }

    .bay-details {
      padding: 0 15px;
    }

    .bay-details {
      align-items: flex-start;
    }

    > .action-group {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      width: auto;

      > .action {
        display: none;
        flex: 0 0 auto;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin-left: 10px;

        &.action-btn {
          width: auto;
        }

        &:first-child {
          margin-left: 0;
        }

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  h4 {
    @include text(14px, true);
    padding: 0;
    margin: 0;
    text-transform: uppercase;

    .remaining {
      @include font-size(12px);
      font-weight: 100;
      text-transform: none;
    }
  }

  .bay-details {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .temperature {
    font-weight: bold;
    margin-right: 0;
  }

  &:hover {
    background-color: var(--state-dark);

    .action {
      display: flex !important;
    }

    .bay-percent {
      display: none;
    }

    .bay-charge-progress {
      display: none;
    }
  }
}

.select-mode .__charger-state-details-bay__c1c79 {
  .bay-header {
    .bay-details {
      padding: 0 15px;
    }
  }

  .action-group {
    .action {
      display: flex !important;
    }

    &:last-child {
      .action {
        display: none !important;
      }
    }
  }

  &--new,
  &--empty-dji,
  &--disconnected,
  &--empty {
    .bay-header {
      .bay-details {
        padding: 0 45px;
      }
    }

    .action-group {
      .action {
        display: none !important;
      }
    }

    &:hover {
      .bay-header {
        .bay-details {
          padding: 0 15px;
        }
      }
    }
  }
}

.__charger-state-details-bay__c1c79 .error-status {
  margin-top: 5px;
  margin-right: 10px;
}

.__charger-state-details-bay__c1c79 .confirm-error {
  padding: 0px 30px;
}

.__charger-state-details-bay__c1c79 .showOnMobile {
  display: flex !important;
}