.__async-loader__dc2b1 {
  $ball-size: 15px !default;
  $margin: 2px !default;
  $line-height: 35px !default;
  $line-width: 4px !default;

  @function delay($interval, $count, $index) {
    @return ($index * $interval) - ($interval * $count);
  }

  @mixin global-bg() {
    background-color: theme-color('primary');
  }

  @mixin global-animation() {
    animation-fill-mode: both;
  }

  @mixin balls() {
    @include global-bg();

    width: $ball-size;
    height: $ball-size;
    border-radius: 100%;
    margin: $margin;
  }

  @mixin lines() {
    @include global-bg();

    width: $line-width;
    height: $line-height;
    border-radius: 2px;
    margin: $margin;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(0.6);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  flex: 1;
  margin: 20px 0;

  .ball-clip-rotate-multiple {
    position: relative;
    height: 35px;
    width: 35px;

    > div {
      @include global-animation();

      position: absolute;
      left: 0;
      top: 0;
      border: 2px solid theme-color('primary');
      border-bottom-color: transparent;
      border-top-color: transparent;
      border-radius: 100%;
      height: 35px;
      width: 35px;
      animation: rotate 1s 0s ease-in-out infinite;

      &:last-child {
        display: inline-block;
        top: 10px;
        left: 10px;
        width: 15px;
        height: 15px;
        animation-duration: 0.5s;
        border-color: theme-color('primary') transparent theme-color('primary') transparent;
        animation-direction: reverse;
      }
    }
  }
}
