.__advanced-header-nav__c8e67 .sideNav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.__advanced-header-nav__c8e67 .sideNav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.__advanced-header-nav__c8e67 .sideNav a:hover {
  color: #f1f1f1;
}

.__advanced-header-nav__c8e67 .sideNav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
.__advanced-header-nav__c8e67 .sideNav {padding-top: 15px;}
.__advanced-header-nav__c8e67 .sideNav a {font-size: 18px;}
}

// Old styles
.__advanced-header-nav__c8e67 {
  height: auto;
  color: $white;

  .navbar {
    padding: 0.5rem 0;
  }

  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;

    li {
      padding: 10px 20px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        @include text(14px, true);
        font-weight: bold;
        transition: opacity 1s ease-out;
        position: relative;
        text-transform: uppercase;
        color: $white;
        display: block;
        height: 100%;
        padding: 5px 0;
        opacity: .3;

        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 3px;
          background-color: theme-color('primary');
          content: '';
        }

        &.active {
          @include text(14px, $font-weight-bold);
          opacity: 1;

          &:after {
            width: 40px;
          }
        }

        &.active,
        &.ember-transitioning-in {
          @include text(14px, $font-weight-bold);
          opacity: 1;

          &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            width: 40px;
            background-color: theme-color('primary');
            content: '';
            animation: linkUnderscoreExpand 1s ease-in;
            animation-fill-mode: forwards;
          }
        }

        &.ember-transitioning-out {
          opacity: .3;

          &:after {
            animation: linkUnderscoreContract 1s ease-out;
            animation-fill-mode: forwards;
          }
        }
      }
    }
  }
}
