.__configure-battery__configure-dji__27d7f h2 {
  @include text(36px);
  margin: 0;
}

.__configure-battery__configure-dji__27d7f button.action {
  @include text(14px);
  padding: 10px 50px;
  margin-left: auto;
  margin-right: 15px;
}

.__configure-battery__configure-dji__27d7f .modal-body-container {
  overflow-x: hidden;
}

.__configure-battery__configure-dji__27d7f .modal-body {
  display: block;
  margin-right: 20px;
}

.__configure-battery__configure-dji__27d7f .modal-footer {
  text-align: right;
}

.__configure-battery__configure-dji__27d7fCellCountSelector {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  label {
    margin-bottom: 10px;
  }

  li {
    margin: 0;

    a {
      transition: color 1ms, background-color .5s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      color: $white;
      border-radius: 30px;

      &.active,
      &:hover {
        background-color: $white;
        color: $background !important;
      }
    }
  }
}

.__configure-battery__configure-dji__27d7fSliderGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .text-muted {
    text-transform: uppercase;
  }

  .text-sub {
    @include text(10px);
  }

  label {
    margin-bottom: 0;
  }

  span {
    @include text(14px);
  }

  &__row {
    display: flex;
    flex-direction: row;
    flex: 1;
    min-height: 50px;
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;

    &--sm {
      flex: 0 0 auto;
      min-width: 30px;
    }
  }
}
