.__reset-password-form__14266 {
  background: none;
  border: none;

  .form-group {
    &:first-child {
      margin-top: 45px;
    }

    &:last-child {
      margin-top: 90px;
    }
  }
}
