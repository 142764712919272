.__battery-fault-card__8c5af {
  position: relative;
  background-color: theme-color('danger');
  border-radius: 4px;
  padding: 10px 15px;
  font-weight: bold;

  a, div {
    color: white;

    span {
      display: block;
    }

    .title {
      text-transform: capitalize;
    }
  }
}
