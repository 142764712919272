.__configure-battery__configure-generic__80a02 h2 {
  @include text(36px);
  margin: 0;
}

.__configure-battery__configure-generic__80a02 button.action {
  @include text(14px);
  padding: 10px 50px;
  margin-left: auto;
  margin-right: 15px;
}

.__configure-battery__configure-generic__80a02 .modal-body-container {
  overflow-x: hidden;
}

.__configure-battery__configure-generic__80a02 .modal-body {
  display: block;
  margin-right: 20px;
}

.__configure-battery__configure-generic__80a02 .modal-footer {
  text-align: right;
}

.__configure-battery__configure-generic__80a02CellCountSelector {
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  label {
    margin-bottom: 10px;
  }

  li {
    margin: 0;

    a,span {
      transition: color 1ms, background-color .5s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 30px;
      width: 30px;
      color: $white;
      border-radius: 30px;
    }

    a {
      &.active,
      &:hover {
        background-color: $white;
        color: $background !important;
      }
    }

    span {
      &.active {
        background-color: $white;
        color: $background !important;
      }
    }
  }
}
