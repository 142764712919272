.__reset-battery-faults__96b92 h2 {
  @include text(36px);
  margin: 0;
}

.__reset-battery-faults__96b92 button.action {
  @include text(14px);
  padding: 10px 30px;
}

.__reset-battery-faults__96b92 .modal-content {
  height: 75vh;
  max-width: 800px;
}

.__reset-battery-faults__96b92 .modal-body-container {
  margin: 2em 0;
  padding: 0 2em;
  overflow-y: scroll;
  overflow-x: hidden;
}

.__reset-battery-faults__96b92 .warning {
  color: $amber;
  font-weight: bold;
}
