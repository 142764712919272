.ember-modal-wrapper {
  display: flex;
  height: auto;

  .ember-modal-overlay {
    position: fixed;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
    z-index: $zindex-modal-backdrop;

    &.translucent {
      background-color: rgba(0,0,0, .8);
    }
  }

  .ember-modal-dialog {
    display: flex;
    position: relative;
    z-index: $zindex-modal;
    margin-top: auto;
    margin-bottom: auto;

    .modal-content {
      margin: 1em 0;
      padding: 2em;

      > div:first-child {
        padding-bottom: 1em;
      }

      > div:last-child {
        padding-top: 1em;
      }
    }

    .modal-dialog {
      margin: 0;

      .modal-body {
        padding: 0;
        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
