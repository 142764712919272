.__auth__confirm__d4bd9 {
  &__container {
    h1 {
      @include text(36px, true);
      margin-bottom: 40px;
    }
    .input-wrapper {
      flex: 1;
      width: 100%;
    }
  }
}
