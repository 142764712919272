.__signin-form__e679f {
  background: none;
  border: none;

  form {
    .validation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 100px;
    }

    &.has-error {
      margin-top: 0;
    }
  }
}
