.__progress-bar__6f60d {
  border-radius: 0;
  height: 3px;
  background-color: var(--state-dark);

  .progress-bar {
    background-color: var(--state-light);
    transition: width 1s ease-in-out;
    border-radius: 0;
  }

  &.vertical {
    width: 1px;
    transition: height 1s ease-in-out;
    transform: rotate(90deg);
  }
}
