.__download-option-list-item__bf04d {
  h4 {
    font-size: 18px;

    &.enlarged-header {
      font-size: 32px;
    }
  }

  li {
    float: left;
    margin-right: 15px;
  }

  a {
    font-weight: bold;
  }
}
