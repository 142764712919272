.__plan-viewer__4b13c {
  display: block;
  padding: 0 25px;

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 40px;

    &.border-bottom {
      border-color: #DBE0E6 !important;
    }
  }

  .alert.alert-danger {
    display: flex;
    flex-direction: row;
    flex: 1;
    background-color: #F3304A;
    color: $white;
    border: none;

    > span {
      display: flex;
      flex-direction: row;
      align-items: center;
      @include text(14px);
      flex: 1 1 auto;

      &:first-child {
        flex: 0 0 auto;
      }

      &:last-child {
        padding-left: 20px;
      }
    }
  }

  sup {
    top: -1em;
  }

  p {
    margin: 0;
  }

  .card-wrapper {
    flex: 1;
  }
}
