
.__line-graph__226bd .graph-title {
  text-align: center;
}

.__line-graph__226bd .graph-container {
  overflow: auto;
  margin-top: -27.5px;
}

.__line-graph__226bd .line {
  fill: none;
  stroke-width: 1.5px;
  pointer-events: none;
}
.__line-graph__226bd .zoom {
  cursor: move;
  fill: none;
  pointer-events: all;
}

.__line-graph__226bd .legend-group {
  background-color: white;
}

.__line-graph__226bd .legend-text {
  cursor: pointer;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

.__line-graph__226bd .legend-data {
  text-decoration: line-through;
}

.__line-graph__226bd .legend-data.active {
  cursor: pointer;
  text-decoration: none;
}