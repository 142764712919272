/**
 * This theme is an example to show how you can create your own.
 */
.pika-single {
  &.dark-theme {
    color: #fff;
    background: #333;
    border: 1px solid #666;
    border-bottom-color: #999;

    .pika-label {
      background-color: #333;
    }

    .pika-prev,
    .is-rtl .pika-next {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAQAAACGG/bgAAAAQ0lEQVR4Ae3KIQ4AIBTD0N0/IeHGI3UIRA3ut/Zl+ltXc5++htVAmIAwAWECwgSEKbgthEoIlRAqIVRCqINQB9nDgQd7ktwFo6UpWQAAAABJRU5ErkJggg==');
    }

    .pika-next,
    .is-rtl .pika-prev {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAQAAACGG/bgAAAAP0lEQVQ4y+3TMQoAMAgEwfwfAvvjTZ1uGzuvHhBPPGczEG+FRqqRaqQaqUaqkX6QBmmjacvQ6qEVTjsh+xizebvlaWptGXZAAAAAAElFTkSuQmCC');
    }

    .pika-table th {
      color: #999;
    }

    .pika-button {
      color: #fff;
      background: #222;

      &:hover {
        color: $background !important;
        background: theme-color('primary') !important;
      }
    }

    .pika-week {
      color: #999;
    }

    .is-today .pika-button {
      color: theme-color('primary');
    }

    .is-selected .pika-button {
      color: #fff;
      background: theme-color('primary');
      box-shadow: inset 0 1px 3px theme-color('primary');
    }

    .is-disabled .pika-button {
      color: #999;
      opacity: .3;
    }
  }
}
