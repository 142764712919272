.__battery-list-item__0bbfc {
  --state-light: #{$white};
  --state-dark: #353535;

  @include text(14px);
  transition: background-color .25s;
  color: theme-color('white') !important;
  border-radius: 4px;
  margin-bottom: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  user-select: none;

  @media (min-width: 768px) 
  {
    padding: 5px 30px;
  }

  @media (max-width: 768px) 
  {
    padding: 5px 0px 5px 30px;
  }

  &:hover {
    background-color: lighten($background, 5%);
  }

  .progress {
    background-color: var(--state-dark);

    .progress-bar {
      background-color: var(--state-light);
    }
  }

  .percent {
    color: var(--state-light);
  }

  .name,
  .status,
  .percent {
    font-weight: bold;
    text-transform: capitalize;
    line-height: 2em;
  }

  .muted {
    opacity: .5;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    span:last-child {
      margin-left: auto;
    }

    .upper {
      display: flex;
      flex-direction: row;
    }
  }

  .btn {
    padding: 3px 15px;
  }

  .showOnMobile {
    display: inline !important;
  }
}

.__battery-list-item__0bbfc--charging, .__battery-list-item__0bbfc--baseline-init, .__battery-list-item__0bbfc--baseline-charging {
  --state-light: #{$shamrock};
  --state-dark: #123027;
}

.__battery-list-item__0bbfc--storage, .__battery-list-item__0bbfc--airplane-storage, .__battery-list-item__0bbfc--baseline-discharging {
  --state-light: #{$amber};
  --state-dark: #4E431E;
}

.__battery-list-item__0bbfc--disconnected:not(.__battery-list-item__0bbfc--faulty), .__battery-list-item__0bbfc--empty:not(.__battery-list-item__0bbfc--faulty) {
  --state-light:#545454;
  --state-dark: #414141;

  .name,
  .status,
  .percent {
    font-weight: bold;
    text-transform: capitalize;
  }

  .name {
    color: theme-color('white');
  }

  button {
    display: none;
  }

  &:hover {
    button {
      display: inline;
    }

    .percent {
      display: none;
    }
  }
}

.__battery-list-item__0bbfc--new, .__battery-list-item__0bbfc--empty-dji {
  --state-dark: #353535;
  --state-light: #{$white};

  background-color: var(--state-light);
  color: var(--state-dark);

  .charge-progress {
    display: none;
  }

  .percent {
    display: none;
  }
}

// This is gross and should just have a single warning class
.__battery-list-item__0bbfc--faulty,
.__battery-list-item__0bbfc--check-balance-leads,
.__battery-list-item__0bbfc--check-battery-leads,
.__battery-list-item__0bbfc--incorrect-battery,
.__battery-list-item__0bbfc--over-voltage,
.__battery-list-item__0bbfc--under-voltage,
.__battery-list-item__0bbfc--cell-imbalance,
.__battery-list-item__0bbfc--over-temperature,
.__battery-list-item__0bbfc--hardware-fault {
  background: theme-color('danger') !important;

  .name,
  .status,
  .percent {
    color: theme-color('white');
    opacity: 1;
  }

  .muted {
    opacity: .5;
  }

  .progress {
    display: none;
  }
}
