.btn {
  @include text(14px);
  padding: 10px 20px;
  border-radius: 5em;
  line-height: normal;
  background: none;
  text-transform: uppercase;
  color: theme-color('white');
  border: 1px solid $white;
  cursor: pointer;
  box-shadow: none;

  &:hover {
    color: $white;
  }

  &.btn-text {
    border: none;
    background: none;
    box-shadow: none;
  }

  &.btn-default {
    background-color: theme-color('white');
    color: $background;
  }

  &.btn-danger {
    @include button-variant($danger, $danger);
  }

  &.btn-primary {
    color: $background;
    background-color: theme-color('primary');
    border-color: theme-color('primary');
  }

  &.btn-hover-primary {
    &:hover {
      background-color: theme-color('primary');
      border-color: theme-color('primary');
      color: $background;
    }
  }

  &[disabled],
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.btn-async {
    position: relative;

    .loader {
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      flex-direction: row;
      width: 100%;
      height: 100%;
    }

    &.loading {
      > span {
        visibility: hidden;
      }

      .loader {
        display: flex;
      }
    }
  }
}

