.__user-initials-bubble__52e58 {
  @include text(24px, true);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: theme-color('white');
  color: $background;
  text-transform: uppercase;
  position: relative;

  &.active {
    background-color: theme-color('primary');
  }

  &.pending {
    opacity: .5;
  }

  i.badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    border: 3px solid $background;
    border-radius: 40px;
    height: 25px;
    width: 25px;

    &.team-owner {
      background-color: $amber;
      bottom: -3px;
      left: -5px;
    }

    &.current-user {
      background-color: $shamrock;
      bottom: -3px;
      right: -5px;
    }
  }
}
