.__auth__signup__168e0 {
  &__nav {
    text-align: right;
    padding: 10px;

    span {
      @include text(13px);
      margin-right: 10px;
    }
  }

  &__container {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      @include text(36px, true);
      margin-bottom: 40px;
    }
  }
}
