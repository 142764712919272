.__page-header-sub-nav__3c464 {
  display: flex;
  flex-direction: row;
  margin: 30px 0 15px 0;
  align-items: center;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    li {
      padding: 0 5px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      a {
        @include text(12.75px);
        font-weight: bold;
        @extend .muted;
        color: theme-color('white');

        &:hover {
          color: theme-color('primary');
        }
      }
    }
  }

  > * {
    &:last-child {
      margin-left: auto;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}
