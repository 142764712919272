.padder-v {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padder-h {
  padding-left: 15px;
  padding-right: 15px;
}

.pull-out {
  margin-left: -15px;
  margin-right: -15px;
}

.w-100 {
  width: 100%;
}

.padder-h-xl {
  padding-left: 30px;
  padding-right: 30px;
}

.text-bold {
  font-weight: bold;
}

.inset {
  padding: 0 30px;
}

.pull-right {
  float: right;
}
