.__activity-timeline__b0f17 {
  position: relative;

  .nav-button {
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 3;

    .btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      margin-bottom: 15px;

      &:hover {
        background-color: #353535;
      }

      &.disabled {
        opacity: .3;
      }

      i {
        @include text(24px);
        line-height: 1em;
      }
    }
  }

  .port {
    display: flex;
    flex-direction: row;
    width: 100%;
    z-index: 2;

    > .port-link {
      display: flex;
      color: #707070;

      > div {
        position: relative;
        border-radius: $border-radius;
        padding: 10px;
        background-color: #353535;

        .activity-shape {
          svg {
            fill: none;
            stroke: #707070;
            stroke-width: 2px;
          }
        }

        .warning-icon {
          position: absolute;
          bottom: 0px;
          right: -5px
        }

        h4 {
          @include text(14px, true);
          width: calc(100%);
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        p {
          @include text(10px, true);
          flex-direction: row;
          padding: 0;
          margin: 0;
          text-transform: uppercase;

          span {
            i {
              margin-right: 4px;
            }

            &:last-child {
              margin-left: auto;
            }
          }
        }
      }

      .activity-metadata {
        display: flex;
      }

      &.active {
        color: $white;

        h4 {
          .activity-title {
            display: block;
          }

          .activity-type {
            display: none;
          }
        }

        .activity-shape {
          margin-top: 25px;
        }

        > div {
          background-color: #353535;
          &:after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(53, 53, 53, 0);
            border-top-color: #353535;
            border-width: 10px;
            margin-left: -10px;
          }
        }
      }

      &:nth-child(1) { animation-delay: 100ms; }
      &:nth-child(2) { animation-delay: 200ms; }
      &:nth-child(3) { animation-delay: 300ms; }
      &:nth-child(4) { animation-delay: 400ms; }
      &:nth-child(5) { animation-delay: 500ms; }
      &:nth-child(6) { animation-delay: 600ms; }
    }
  }
}
