.__confirmation-resend__fe626 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .success-animation {
    max-height: 200px;
    max-width: 200px;
  }
}
