// Current Mixins
@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin text($size: 1em, $isBold: false) {
  @include font-size($size);
  font-family: $font-family-base;

  @if $isBold {
    font-weight: bold;
  }

  @content;
}

$prefix-for-webkit:    true;
$prefix-for-mozilla:   true;
$prefix-for-microsoft: true;
$prefix-for-opera:     true;
$prefix-for-spec:      true;

@mixin prefixer($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      @if $prefix-for-webkit {
        -webkit-#{$property}: $value;
      }
    } @else if $prefix == moz {
      @if $prefix-for-mozilla {
        -moz-#{$property}: $value;
      }
    } @else if $prefix == ms {
      @if $prefix-for-microsoft {
        -ms-#{$property}: $value;
      }
    } @else if $prefix == o {
      @if $prefix-for-opera {
        -o-#{$property}: $value;
      }
    } @else if $prefix == spec {
      @if $prefix-for-spec {
        #{$property}: $value;
      }
    } @else  {
      @warn "Unrecognized prefix: #{$prefix}";
    }
  }
}
