.__user-confirm__c3670 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .success-animation {
    max-height: 200px;
    max-width: 200px;
    margin: 40px 0;
  }
}
