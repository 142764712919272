.__user-account__76111 {
  &__UserIcon {
    @include text(24px, true);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    background: theme-color('primary');
    border-radius: 30px;
    color: $background;
    text-transform: uppercase;
  }

  &__UserInfo {
    padding: 10px;
    h4 {
      @include text(18px, true);
      padding: 0;
      margin: 0;
    }
    a {
      @include text(14px, true);
    }
  }

  .account-section {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-700;

    .actions {
      a {
        @include text(14px, true);
        color: theme-color('primary');
        text-transform: uppercase;
        padding-top: 15px;
      }
    }

    span {
      @include text(10px, true);
    }

    p {
      @include text(18px, true);
      margin: 0;

      i {
        @include text(14px, true);
      }
    }
  }
}
