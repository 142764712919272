.__charger-states-list-item-bay__7a323 {
  --state-light: #{$white};
  --state-dark: #353535;

  @include text(14px);
  transition: background-color .25s;
  color: var(--state-light);
  border-radius: 4px;
  margin-bottom: 3px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;

  &:hover {
    padding: 10px 15px;
    background-color: var(--state-dark);

    .action {
      display: flex !important;
    }

    .bay-percent {
      display: none;
    }

    .bay-charge-progress {
      display: none;
    }
  }

  .progress {
    background-color: var(--state-dark);

    .progress-bar {
      background-color: var(--state-light);
    }
  }

  .bay-name {
    text-transform: uppercase;
  }

  .bay-status {
    text-transform: capitalize;
  }

  .muted {
    opacity: .5;
  }

  & > .action-group {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-left: auto;
    width: auto;

    > .action {
      display: none;
      flex: 0 0 auto;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      margin-left: 10px;

      &.action-btn {
        width: auto;
      }

      &:first-child {
        margin-left: 0;
      }

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &.showOnMobile {
        display: flex !important;
      }
    }
  }

  .bay-details {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    cursor: pointer;

    span:last-child {
      margin-left: auto;
    }
  }
}

.__charger-states-list-item-bay__7a323--charging, .__charger-states-list-item-bay__7a323--baseline-init, .__charger-states-list-item-bay__7a323--baseline-charging {
  --state-light: #{$shamrock};
  --state-dark: #123027;
}

.__charger-states-list-item-bay__7a323--faulty,
.__charger-states-list-item-bay__7a323--check-balance-leads,
.__charger-states-list-item-bay__7a323--check-battery-leads,
.__charger-states-list-item-bay__7a323--incorrect-battery,
.__charger-states-list-item-bay__7a323--over-voltage,
.__charger-states-list-item-bay__7a323--under-voltage,
.__charger-states-list-item-bay__7a323--cell-imbalance,
.__charger-states-list-item-bay__7a323--over-temperature,
.__charger-states-list-item-bay__7a323--hardware-fault {
  background: theme-color('danger') !important;

  .name,
  .status,
  .percent {
    color: theme-color('white');
    opacity: 1;
  }

  .muted {
    opacity: .5;
  }

  .progress {
    display: none;
  }
}

.__charger-states-list-item-bay__7a323--baseline-hold, .__charger-states-list-item-bay__7a323--baseline-init-hold, .__charger-states-list-item-bay__7a323--charging-hold, .__charger-states-list-item-bay__7a323--storage-hold, .__charger-states-list-item-bay__7a323--configuration-error {
  background: #67b8ff !important;
  .btn {
    padding: 0.375rem 0.75rem;
  }
  
  .name,
  .status,
  .percent {
    color: theme-color('white');
    opacity: 1;
  }

  .muted {
    opacity: .5;
  }

  .progress {
    display: none;
  }
}

.__charger-states-list-item-bay__7a323--storage, .__charger-states-list-item-bay__7a323--airplane-storage, .__charger-states-list-item-bay__7a323--baseline-discharging {
  --state-light: #{$amber};
  --state-dark: #4E431E;
}

.__charger-states-list-item-bay__7a323--disconnected, .__charger-states-list-item-bay__7a323--empty {
  --state-light:#545454;
  --state-dark: #414141;

  &:hover {
    background-color: $background;

    .action {
      display: none !important;
    }

    .bay-charge-progress {
      display: block;
    }
  }
}

.__charger-states-list-item-bay__7a323--disconnected,
.__charger-states-list-item-bay__7a323--charging,
.__charger-states-list-item-bay__7a323--storage,
.__charger-states-list-item-bay__7a323--airplane-storage,
.__charger-states-list-item-bay__7a323--baseline-init,
.__charger-states-list-item-bay__7a323--baseline-charging,
.__charger-states-list-item-bay__7a323--baseline-discharging,
.__charger-states-list-item-bay__7a323--faulty,
.__charger-states-list-item-bay__7a323--check-balance-leads,
.__charger-states-list-item-bay__7a323--check-battery-leads,
.__charger-states-list-item-bay__7a323--incorrect-battery,
.__charger-states-list-item-bay__7a323--over-voltage,
.__charger-states-list-item-bay__7a323--under-voltage,
.__charger-states-list-item-bay__7a323--cell-imbalance,
.__charger-states-list-item-bay__7a323--over-temperature,
.__charger-states-list-item-bay__7a323--hardware-fault,
.__charger-states-list-item-bay__7a323--baseline-hold,
.__charger-states-list-item-bay__7a323--baseline-init-hold,
.__charger-states-list-item-bay__7a323--charging-hold,
.__charger-states-list-item-bay__7a323--storage-hold,
.__charger-states-list-item-bay__7a323--configuration-error {
  > .action-group {
    &:first-child {
      .action {
        display: none !important;
      }
    }
  }
}

.__charger-states-list-item-bay__7a323--new, .__charger-states-list-item-bay__7a323--empty-dji {
  --state-dark: #353535;
  --state-light: #{$white};

  color: var(--state-dark);

  > .action-group {
    &:first-child {
      .action {
        display: none !important;
      }
    }

    .action {
      > .btn {
        transition: background-color .5s;
        background: $white;
        border: 1px solid var(--state-dark);

        &:hover {
          color: $white;
          background-color: var(--state-dark);
        }
      }
    }

    &:last-child {
      > .action {
        display: flex !important;

        .btn-action {
          fill: var(--state-dark);
          background: var(--state-light);
        }

        &:hover {
          .btn-action {
            fill: var(--state-light);
            background: var(--state-dark) !important;
          }
        }
      }
    }
  }

  .bay-charge-progress {
    display: none;
  }

  .bay-percent {
    display: none;
  }
}

.__charger-states-list-item-bay__7a323--new {
  --state-light: #{$white};

  background-color: var(--state-light);

  &:hover {
    background-color: var(--state-light);
  }
}

.__charger-states-list-item-bay__7a323--empty-dji {
  --state-light: rgb(191, 191, 191);

  background-color: var(--state-light);

  &:hover {
    background-color: var(--state-light);
  }
}

.select-mode .__charger-states-list-item-bay__7a323 {
  .action-group {
    .action {
      display: flex !important;
    }
    
    &:last-child {
      .action {
        display: none !important;
      }
    }
  }

  &--new,
  &--empty-dji,
  &--disconnected,
  &--empty,
  &--faulty,
  &--check-balance-leads,
  &--check-battery-leads,
  &--incorrect-battery,
  &--over-voltage,
  &--under-voltage,
  &--cell-imbalance,
  &--over-temperature,
  &--hardware-fault,
  &--baseline-hold,
  &--baseline-init-hold,
  &--charging-hold,
  &--storage-hold,
  &--configuration-error {
    .action-group {
      .action {
        display: none !important;
      }
    }
  }
}
