.__charger-states-list-item__d3ebd {
  margin-bottom: 45px;

  header {
    @include text(14px);
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;

    &:hover {
      .action-group {
        &:last-child {
          margin-left: auto;
        }
        .action {
          display: flex;
        }
      }
    }

    > span {
      padding: 0 15px;
      flex: 1;
    }

    .action-group {
      width: 30px;

      .action {
        display: none;
        margin-right: 10px;
        height: 30px;
        width: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        fill: $white;
      }
    }
  }
}

.select-mode .__charger-states-list-item__d3ebd {
  .action-group {
    &:first-child {
      .action {
        height: 30px;
        width: 30px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        fill: $white;
      }
    }

    .action {
      display: flex;
    }
  }
}
